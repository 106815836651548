import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import {Grid, GridCol, HoverItem, Layout, SEO} from "../components";

const IndexPage = () => {

    const { allMdx } = useStaticQuery(
      graphql`
      query {
        allMdx(
          filter: {
            fileAbsolutePath: {regex: "/work/"},
            frontmatter: {featured: {eq: true}}
          },
          sort: { 
            fields: [frontmatter___date], 
            order: DESC 
          }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                images {
                 childImageSharp {
                   fluid(maxWidth: 290, quality: 100) {
                      ...GatsbyImageSharpFluid
                   }
                 }
                }
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
    )

    return (
      <Layout>
        <SEO title="Home"/>
        <Grid className={'spacing-bottom--lg'}>
          { allMdx.edges.map((item,i) => (
            <GridCol className={'col-4 col-sm-12'} key={`featured-project-${i}`}>
              <HoverItem
                img={item.node.frontmatter.images ? item.node.frontmatter.images[0].childImageSharp.fluid : null}
                title={item.node.frontmatter.title}
                link={item.node.fields.slug}
              />
            </GridCol>
          ))}
        </Grid>
      </Layout>
    )
}

export default IndexPage